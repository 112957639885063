// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as Screens from "../../../styleguide/styles/Screens.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ProductTile from "../../product-index/product-tile/ProductTile.res.js";
import ReactSlick from "react-slick";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductSliderScss from "./ProductSlider.scss";

var css = ProductSliderScss;

var initialState = {
  isClient: false,
  sliderStyle: "Shown"
};

function reducer(state, action) {
  if (typeof action !== "object") {
    return {
            isClient: true,
            sliderStyle: state.sliderStyle
          };
  } else {
    return {
            isClient: state.isClient,
            sliderStyle: action._0
          };
  }
}

function ProductSlider(props) {
  var __mobile = props.mobile;
  var __toggleCart = props.toggleCart;
  var __setCartQty = props.setCartQty;
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var products = props.products;
  var setCartQty = __setCartQty !== undefined ? __setCartQty : (function (prim) {
        
      });
  var toggleCart = __toggleCart !== undefined ? __toggleCart : (function (prim) {
        
      });
  var mobile = __mobile !== undefined ? __mobile : false;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("SetIsClient");
        }), []);
  var match$1 = products.length;
  var tmp;
  if (state.isClient === true) {
    var match$2 = products.length;
    tmp = [
      {
        breakpoint: Screens.smallSlider,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "20px",
          rows: 1,
          slidesPerRow: 1,
          infinite: true
        }
      },
      {
        breakpoint: Screens.small,
        settings: {
          slidesToShow: 2,
          arrows: false,
          centerMode: true,
          centerPadding: "20px",
          rows: 1,
          slidesPerRow: 1,
          infinite: true
        }
      },
      {
        breakpoint: Screens.large,
        settings: {
          slidesToShow: match$2 !== 1 ? 2 : 1,
          arrows: true,
          centerMode: false,
          centerPadding: "20px",
          rows: 1,
          slidesPerRow: 1,
          infinite: true
        }
      }
    ];
  } else {
    tmp = undefined;
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ReactSlick, {
                    dots: false,
                    infinite: true,
                    slidesToShow: match$1 !== 1 ? (
                        match$1 !== 2 ? 3 : 2
                      ) : 1,
                    slidesToScroll: 1,
                    responsive: tmp,
                    rows: 1,
                    slidesPerRow: 1,
                    arrows: true,
                    children: Belt_Array.map(products, (function (product) {
                            return JsxRuntime.jsx(ProductTile.make, {
                                        product: product,
                                        className: css.product,
                                        userLoginStatus: userLoginStatus,
                                        tooltipPosition: "Top",
                                        setUserData: setUserData,
                                        setCartQty: setCartQty,
                                        toggleCart: toggleCart,
                                        mobile: mobile,
                                        setSliderStyle: (function (toggle) {
                                            dispatch({
                                                  TAG: "SetSliderStyle",
                                                  _0: toggle
                                                });
                                          })
                                      }, ID.toString(product.id));
                          }))
                  }, state.isClient ? "product-slider-client" : "product-slider-server"),
              className: Cx.cx([
                    css.sliderContainer,
                    state.sliderStyle === "Hidden" ? css.slickSliderOverride : ""
                  ])
            });
}

var make = ProductSlider;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
