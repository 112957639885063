// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as Screens from "../../../styleguide/styles/Screens.res.js";
import * as LightBox from "../../../bindings/LightBox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import ReactImages from "react-images";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductViewScss from "../../product-detail/product-detail-view/ProductView.scss";

var css = ProductViewScss;

var thumbLarge = [
  208,
  138
];

var thumbMedium = [
  158,
  105
];

var thumbSmall = [
  240,
  160
];

var singleThumbLarge = [
  320,
  240
];

var singleThumbMedium = [
  243,
  182
];

var singleThumbSmall = [
  369,
  278
];

function getThumb(imagesCount, videosCount, size) {
  if (imagesCount === 1 && videosCount === 0) {
    switch (size) {
      case "Large" :
          return singleThumbLarge;
      case "Medium" :
          return singleThumbMedium;
      case "Small" :
          return singleThumbSmall;
      
    }
  } else {
    switch (size) {
      case "Large" :
          return thumbLarge;
      case "Medium" :
          return thumbMedium;
      case "Small" :
          return thumbSmall;
      
    }
  }
}

var Const = {
  thumbLarge: thumbLarge,
  thumbMedium: thumbMedium,
  thumbSmall: thumbSmall,
  singleThumbLarge: singleThumbLarge,
  singleThumbMedium: singleThumbMedium,
  singleThumbSmall: singleThumbSmall,
  maxVisibleItemsSmall: 4,
  maxVisibleItems: 4,
  getThumb: getThumb
};

function getFirstN(items, n) {
  return Belt_Array.slice(items, 0, n);
}

function maxItems(items) {
  var itemsTotal = items.length;
  if (itemsTotal > 4) {
    return 4;
  } else {
    return itemsTotal;
  }
}

function notVisibleItemsSize(itemsSize, maxVisibleItemsSize) {
  if (itemsSize > maxVisibleItemsSize) {
    return itemsSize - maxVisibleItemsSize | 0;
  } else {
    return 0;
  }
}

var Helpers = {
  getFirstN: getFirstN,
  maxItems: maxItems,
  notVisibleItemsSize: notVisibleItemsSize
};

function reducer(state, action) {
  if (typeof action === "object") {
    if (action.TAG === "Resize") {
      return {
              maxItems: action._0,
              isLightBoxOpen: state.isLightBoxOpen,
              currentImageIndex: state.currentImageIndex
            };
    } else {
      return {
              maxItems: state.maxItems,
              isLightBoxOpen: true,
              currentImageIndex: action._0
            };
    }
  }
  switch (action) {
    case "CloseLightBox" :
        return {
                maxItems: state.maxItems,
                isLightBoxOpen: false,
                currentImageIndex: 0
              };
    case "GotoPrevious" :
        return {
                maxItems: state.maxItems,
                isLightBoxOpen: state.isLightBoxOpen,
                currentImageIndex: state.currentImageIndex - 1 | 0
              };
    case "GotoNext" :
        return {
                maxItems: state.maxItems,
                isLightBoxOpen: state.isLightBoxOpen,
                currentImageIndex: state.currentImageIndex + 1 | 0
              };
    
  }
}

function ProductDetailImagesGrid(props) {
  var __imageGridItemCn = props.imageGridItemCn;
  var __className = props.className;
  var images = props.images;
  var videosCount = props.videosCount;
  var className = __className !== undefined ? __className : css.imagesContainer;
  var imageGridItemCn = __imageGridItemCn !== undefined ? __imageGridItemCn : "";
  var initialState = React.useMemo((function () {
          return {
                  maxItems: maxItems(images),
                  isLightBoxOpen: false,
                  currentImageIndex: 0
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToResize(function (param) {
                      var maxItems = 4;
                      dispatch({
                            TAG: "Resize",
                            _0: maxItems
                          });
                    });
        }), []);
  return JsxRuntime.jsxs("div", {
              children: [
                Belt_Array.mapWithIndex(Belt_Array.slice(images, 0, state.maxItems), (function (index, image) {
                        var tmp;
                        if (index === (state.maxItems - 1 | 0) && notVisibleItemsSize(images.length, state.maxItems) > 0) {
                          var number = notVisibleItemsSize(images.length, state.maxItems);
                          tmp = JsxRuntime.jsx("div", {
                                children: number !== 1 ? String(number) + " photos" : "1 photo",
                                className: css.imageGridItemOverlay
                              });
                        } else {
                          tmp = null;
                        }
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Picture.make, {
                                            src: image.url,
                                            large: getThumb(images.length, videosCount, "Large"),
                                            medium: getThumb(images.length, videosCount, "Medium"),
                                            small: getThumb(images.length, videosCount, "Small")
                                          }),
                                      tmp
                                    ],
                                    className: Cx.cx([
                                          css.imageGridItem,
                                          imageGridItemCn,
                                          images.length === 1 && videosCount === 0 ? css.single : ""
                                        ]),
                                    onClick: (function (param) {
                                        dispatch({
                                              TAG: "OpenLightBox",
                                              _0: index
                                            });
                                      })
                                  }, image.publicId);
                      })),
                JsxRuntime.jsx(ReactImages, {
                      currentImage: state.currentImageIndex,
                      images: Belt_Array.map(images, LightBox.GalleryImage.fromImage),
                      isOpen: state.isLightBoxOpen,
                      onClickPrev: (function () {
                          dispatch("GotoPrevious");
                        }),
                      onClickNext: (function () {
                          dispatch("GotoNext");
                        }),
                      onClickImage: (function () {
                          if (state.currentImageIndex < (images.length - 1 | 0)) {
                            return dispatch("GotoNext");
                          }
                          
                        }),
                      onClose: (function () {
                          dispatch("CloseLightBox");
                        })
                    })
              ],
              className: Cx.cx([
                    css.imagesContainer,
                    className
                  ])
            });
}

var make = ProductDetailImagesGrid;

export {
  css ,
  Const ,
  Helpers ,
  reducer ,
  make ,
}
/* css Not a pure module */
