// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../utils/Url.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as IconRFP from "../../../styleguide/icons/IconRFP.res.js";
import * as IconSolidCart from "../../../styleguide/icons/IconSolidCart.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import IsPast from "date-fns/isPast";
import * as JsxRuntime from "react/jsx-runtime";
import * as DetailsSidebarScss from "./DetailsSidebar.scss";
import * as ProductDetailsForSidebar from "../product-details-for-sidebar/ProductDetailsForSidebar.res.js";

var css = DetailsSidebarScss;

function DetailsSidebar(props) {
  var __openModal = props.openModal;
  var product = props.product;
  var openModal = __openModal !== undefined ? __openModal : (function (prim) {
        
      });
  var productIsExpired = IsPast(product.endDate);
  return JsxRuntime.jsxs(ProductDetailsForSidebar.make, {
              product: product,
              className: css.detailsSideBar,
              userLoginStatus: props.userLoginStatus,
              setUserData: props.setUserData,
              children: [
                JsxRuntime.jsxs(Button.make, {
                      size: "MD",
                      color: "Primary",
                      ghost: false,
                      expanded: true,
                      disabled: productIsExpired,
                      visuallyDisabled: productIsExpired,
                      busy: false,
                      buttonClassName: css.buttonRFP,
                      onClick: (function (param) {
                          Url.visit(Routes_Project.Product.Colocation.$$new(product.id));
                        }),
                      children: [
                        JsxRuntime.jsx(IconRFP.make, {
                              title: "RFP",
                              size: "SM",
                              className: css.buttonIcon
                            }),
                        "Customize Requirements"
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: "Start an RFP and get a proposal for your custom requirements",
                      className: css.finePrint
                    }),
                productIsExpired ? null : JsxRuntime.jsxs(Button.make, {
                        size: "MD",
                        color: "Teal",
                        expanded: true,
                        className: css.button,
                        onClick: (function (param) {
                            openModal();
                          }),
                        children: [
                          JsxRuntime.jsx(IconSolidCart.make, {
                                size: "SM",
                                color: "White",
                                className: css.buttonIcon
                              }),
                          "Add to Cart"
                        ]
                      }),
                JsxRuntime.jsx("div", {
                      children: "* No payment required.",
                      className: css.finePrint
                    })
              ]
            });
}

var make = DetailsSidebar;

export {
  css ,
  make ,
}
/* css Not a pure module */
