// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Api from "../../../api/Api.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as LocationMap from "../../common/marketplace/LocationMap.res.js";
import * as DetailVideos from "../../common/detail-videos/DetailVideos.res.js";
import * as ProductSpecs from "../product-specs/ProductSpecs.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ProductSlider from "../products-slider/ProductSlider.res.js";
import * as AddToCartModal from "../cart-modal/AddToCartModal.res.js";
import * as DetailsSidebar from "../details-sidebar/DetailsSidebar.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductViewScss from "./ProductView.scss";
import * as ProductDescription from "../product-description/ProductDescription.res.js";
import * as ProductDetailImagesGrid from "../../common/marketplace/ProductDetailImagesGrid.res.js";

var css = ProductViewScss;

var initialState = {
  status: "Closed",
  currentItemQty: 1
};

function ProductView$ProductView(props) {
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var mobile = props.mobile;
  var setCartQty = props.setCartQty;
  var toggleCart = props.toggleCart;
  var providerProducts = props.providerProducts;
  var product = props.product;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "OpenModal" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Opened",
                            currentItemQty: state.currentItemQty
                          },
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "AddToCart",
                                    _0: 1
                                  });
                            })
                        };
              case "CloseModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "Closed",
                            currentItemQty: state.currentItemQty
                          }
                        };
              case "OpenCartList" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "Closed",
                            currentItemQty: state.currentItemQty
                          },
                          _1: (function (param) {
                              toggleCart();
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateQty" :
                  var qty = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: state,
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "AddToCart",
                                    _0: qty
                                  });
                            })
                        };
              case "UpdateCurrentItemQty" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentItemQty: action._0
                          }
                        };
              case "AddToCart" :
                  var qty$1 = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: state,
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api.addToCart(qty$1, product.id), (function (x) {
                                      if (x.TAG !== "Ok") {
                                        return SentryLogger.error1({
                                                    rootModule: "ProductView",
                                                    subModulePath: {
                                                      hd: "ProductView",
                                                      tl: /* [] */0
                                                    },
                                                    value: "make",
                                                    fullPath: "ProductView.ProductView.make"
                                                  }, "ProductView::AddToCart::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                      var res = x._0;
                                      setCartQty(res.cartQty);
                                      dispatch({
                                            TAG: "UpdateCurrentItemQty",
                                            _0: res.productQty
                                          });
                                      if (qty$1 === 0) {
                                        return dispatch("CloseModal");
                                      }
                                      
                                    }));
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.status;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(DetailsSidebar.make, {
                            product: product,
                            openModal: (function () {
                                dispatch("OpenModal");
                              }),
                            userLoginStatus: userLoginStatus,
                            setUserData: setUserData
                          }),
                      className: css.sidebarContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Breadcrumb.make, {
                              breadcrumbLinks: [
                                {
                                  linkName: "Home",
                                  linkPath: "/"
                                },
                                {
                                  linkName: "Marketplace",
                                  linkPath: "/marketplace"
                                },
                                {
                                  linkName: $$String.capitalize_ascii(product.category),
                                  linkPath: "/marketplace/" + product.seoCategory
                                },
                                {
                                  linkName: product.name,
                                  linkPath: product.url
                                }
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(ProductDescription.make, {
                                                    product: product
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      product.videos.length !== 0 ? JsxRuntime.jsx(DetailVideos.make, {
                                                              videos: product.videos
                                                            }) : null,
                                                      JsxRuntime.jsx(ProductDetailImagesGrid.make, {
                                                            videosCount: product.videos.length,
                                                            images: product.images
                                                          })
                                                    ],
                                                    className: css.assetsContainer
                                                  })
                                            ],
                                            className: css.descriptionAssetsContainer
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(LocationMap.make, {
                                                  location: LocationMap.propsFromLocation(product.location),
                                                  serverSide: props.serverSide
                                                }),
                                            className: css.mapContainer
                                          }),
                                      JsxRuntime.jsx(ProductSpecs.make, {
                                            location: product.location
                                          })
                                    ],
                                    className: css.product
                                  }),
                              className: css.descriptionContainer
                            }),
                        providerProducts.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        className: css.divider
                                      }),
                                  JsxRuntime.jsx(H2.make, {
                                        className: css.otherProducts,
                                        children: "Other " + (product.providerName + " Products")
                                      }),
                                  JsxRuntime.jsx(ProductSlider.make, {
                                        products: providerProducts,
                                        userLoginStatus: userLoginStatus,
                                        setUserData: setUserData,
                                        setCartQty: setCartQty,
                                        toggleCart: toggleCart,
                                        mobile: mobile
                                      })
                                ]
                              }) : null,
                        match$1 === "Opened" ? JsxRuntime.jsx(AddToCartModal.make, {
                                product: product,
                                toggleCart: (function () {
                                    dispatch("OpenCartList");
                                  }),
                                close: (function () {
                                    dispatch("CloseModal");
                                  }),
                                currentItemQty: state.currentItemQty,
                                updateQty: (function (qty) {
                                    dispatch({
                                          TAG: "UpdateQty",
                                          _0: qty
                                        });
                                  }),
                                mobile: mobile,
                                userLoginStatus: userLoginStatus
                              }) : null
                      ],
                      className: css.productContainer
                    })
              ],
              className: css.productViewLayout
            });
}

var ProductView = {
  css: css,
  initialState: initialState,
  make: ProductView$ProductView
};

function ProductView$default(props) {
  var reduxfunction = props.reduxfunction;
  var context = props.context;
  var props$1 = props.props;
  return JsxRuntime.jsx(ProductView$ProductView, {
              product: ColocationProduct.fromJs(props$1.product),
              providerProducts: Belt_Array.map(props$1.providerProducts, ColocationProduct.fromJs),
              serverSide: context.serverSide,
              toggleCart: reduxfunction.toggleCart,
              setCartQty: reduxfunction.setCartQty,
              mobile: context.mobile,
              userLoginStatus: User.LoginStatus.fromBool(context.isUserSignedIn),
              setUserData: reduxfunction.setUserData
            });
}

var $$default = ProductView$default;

export {
  ProductView ,
  $$default as default,
}
/* css Not a pure module */
