// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ProductTerm from "../../../models/ProductTerm.res.js";
import * as WithDataProps from "../../../styleguide/components/WithDataProps/WithDataProps.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalContainer from "../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProductDetailsForSidebarScss from "./ProductDetailsForSidebar.scss";

var css = ProductDetailsForSidebarScss;

function reload() {
  window.location.reload();
}

function buildLogoUrl(src) {
  return Cloudinary.Url.build("Png", {
              hd: {
                TAG: "Height",
                _0: 60
              },
              tl: /* [] */0
            }, src);
}

var Helpers = {
  buildLogoUrl: buildLogoUrl
};

function reducer(state, action) {
  if (typeof action !== "object") {
    return {
            showDetails: !state.showDetails,
            signInModal: state.signInModal
          };
  } else {
    return {
            showDetails: state.showDetails,
            signInModal: action._0
          };
  }
}

function ProductDetailsForSidebar(props) {
  var userLoginStatus = props.userLoginStatus;
  var __toggleDetails = props.toggleDetails;
  var __className = props.className;
  var product = props.product;
  var className = __className !== undefined ? __className : "";
  var toggleDetails = __toggleDetails !== undefined ? __toggleDetails : false;
  var initialState = React.useMemo((function () {
          return {
                  showDetails: !toggleDetails,
                  signInModal: "Hidden"
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  tmp = userLoginStatus === "LoggedIn" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(WithDataProps.make, {
                  props: {
                    "data-test-id": "product-detail-setup-fee"
                  },
                  children: JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("span", {
                                children: "Setup fee",
                                className: css.specCategory
                              }),
                          JsxRuntime.jsx("span", {
                                children: Money.formatWithCents(product.setupFee),
                                className: css.specItem
                              })
                        ],
                        className: css.specsRow
                      })
                }),
            JsxRuntime.jsx("div", {
                  className: css.divider
                })
          ]
        }) : null;
  var tmp$1;
  tmp$1 = userLoginStatus === "LoggedIn" ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  className: css.divider
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("span", {
                          children: "Price",
                          className: css.specCategory
                        }),
                    JsxRuntime.jsx("span", {
                          children: JsxRuntime.jsx(Control.make, {
                                onClick: (function (param) {
                                    dispatch({
                                          TAG: "ToggleSignInModal",
                                          _0: "Shown"
                                        });
                                  }),
                                children: "Sign In to See Pricing"
                              }),
                          className: css.specItem
                        })
                  ],
                  className: css.specsRow
                })
          ]
        });
  var tmp$2;
  tmp$2 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsx(WithDataProps.make, {
          props: {
            "data-test-id": "product-detail-price"
          },
          children: JsxRuntime.jsxs("div", {
                children: [
                  Money.formatWithCents(product.pricePerMonth),
                  JsxRuntime.jsx("span", {
                        children: "/month",
                        className: css.perMonth
                      })
                ]
              })
        }) : null;
  var match$1 = state.signInModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        product.isExclusiveOffer ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("span", {
                                      children: "Exclusive",
                                      className: css.bannerText
                                    }),
                                className: css.exclusiveBanner
                              }) : null,
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Link.make, {
                                    href: product.providerUrl,
                                    children: JsxRuntime.jsx("img", {
                                          className: css.logo,
                                          src: buildLogoUrl(product.providerLogo)
                                        })
                                  }),
                              className: css.logoContainer
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: product.name,
                                    className: css.overflow
                                  }),
                              className: css.productName
                            }),
                        toggleDetails && !state.showDetails ? JsxRuntime.jsx(Control.AsLink.make, {
                                className: css.showDetails,
                                onClick: (function (param) {
                                    dispatch("ToggleDetails");
                                  }),
                                children: "Show details"
                              }) : null,
                        JsxRuntime.jsx("div", {
                              children: "Located in " + (product.location.name + (" (" + (product.location.fullAddress + ")"))),
                              className: Cx.cx([
                                    css.productInfo,
                                    toggleDetails && !state.showDetails ? css.hideDetails : ""
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Space",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: product.subcategory,
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                JsxRuntime.jsx("div", {
                                      className: css.divider
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Product power",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: String(product.totalPowerKw) + " kW",
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                JsxRuntime.jsx("div", {
                                      className: css.divider
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Power circuit",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: String(Belt_Array.get(Js_string.split("(", product.powerCircuit), 0)),
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                JsxRuntime.jsx("div", {
                                      className: css.divider
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Speed",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: product.bandwidth,
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                JsxRuntime.jsx("div", {
                                      className: css.divider
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "IPs",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: product.ipAllocation,
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                JsxRuntime.jsx("div", {
                                      className: css.divider
                                    }),
                                tmp,
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Term",
                                              className: css.specCategory
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: ProductTerm.toLabel(product.term),
                                              className: css.specItem
                                            })
                                      ],
                                      className: css.specsRow
                                    }),
                                tmp$1
                              ],
                              className: Cx.cx([
                                    css.specsContainer,
                                    toggleDetails && !state.showDetails ? css.hideDetails : ""
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                tmp$2,
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "Sale ends on "
                                            }),
                                        Format(product.endDate, "MMM d")
                                      ],
                                      className: css.saleEndDate
                                    })
                              ],
                              className: css.pricing
                            }),
                        Belt_Option.getWithDefault(props.children, null)
                      ],
                      className: Cx.cx([
                            css.detailsContainer,
                            className
                          ])
                    }),
                match$1 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch({
                                  TAG: "ToggleSignInModal",
                                  _0: "Hidden"
                                });
                          }),
                        callback: (function () {
                            window.location.reload();
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to see product prices"
                      }) : null
              ]
            });
}

var make = ProductDetailsForSidebar;

export {
  css ,
  reload ,
  Helpers ,
  reducer ,
  make ,
}
/* css Not a pure module */
