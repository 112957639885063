// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Globals from "Common/globals";
import * as CloudinaryReact from "cloudinary-react";
import * as JsxRuntime from "react/jsx-runtime";

var JsComponent = {};

function ReactCloudinaryVideo(props) {
  return JsxRuntime.jsx(CloudinaryReact.Video, {
              "data-cld-public-id": props.publicId,
              preload: props.preload,
              controls: props.controls,
              autoPlay: props.autoPlay,
              "data-cld-poster-options": props.posterOptions,
              className: props.className,
              cloudName: Globals.CloudinaryCloudName
            });
}

var make = ReactCloudinaryVideo;

export {
  JsComponent ,
  make ,
}
/* Common/globals Not a pure module */
