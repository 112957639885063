// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cloudinary from "../libs/Cloudinary.res.js";

function buildUrl(src) {
  return Cloudinary.Url.build("Auto", {
              hd: "Dpr",
              tl: {
                hd: "Quality",
                tl: {
                  hd: {
                    TAG: "FetchFormat",
                    _0: "Auto"
                  },
                  tl: /* [] */0
                }
              }
            }, src);
}

function fromImage(image) {
  return {
          src: buildUrl(image.url),
          caption: image.description
        };
}

var GalleryImage = {
  buildUrl: buildUrl,
  fromImage: fromImage
};

export {
  GalleryImage ,
}
/* Cloudinary Not a pure module */
