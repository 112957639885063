// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconPlay(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Play";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M8,16 C3.58172201,16 0,12.418278 0,8 C0,3.58172201 3.58172201,0 8,0 C12.418278,0 16,3.58172201 16,8 C16,12.418278 12.418278,16 8,16 Z M7.13142857,5.12 C7.0522989,5.06065273 6.95605495,5.02857143 6.85714286,5.02857143 C6.60466983,5.02857143 6.4,5.23324126 6.4,5.48571429 L6.4,10.5142857 C6.4,10.6131978 6.4320813,10.7094418 6.49142857,10.7885714 C6.64291239,10.9905499 6.92945015,11.0314838 7.13142857,10.88 L10.4838095,8.36571429 C10.5184635,8.33972377 10.5492476,8.30893973 10.5752381,8.27428571 C10.7267219,8.07230729 10.685788,7.78576953 10.4838095,7.63428571 L7.13142857,5.12 Z",
                    fill: Icon.mapColor(props.color),
                    fillRule: "evenodd"
                  })
            });
}

var make = IconPlay;

export {
  make ,
}
/* Icon Not a pure module */
