// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Control from "../../components/Control/Control.res.js";
import * as IconDot from "../../icons/IconDot.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as VideoPlayer from "../../components/VideoPlayer/VideoPlayer.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as VideoThumbnailSlide from "./VideoThumbnailSlide.res.js";
import * as VideoCarouselScss from "./VideoCarousel.scss";

var css = VideoCarouselScss;

var initialState = {
  currentVideoIndex: 0,
  status: "Viewing"
};

function VideoCarousel(props) {
  var videos = props.videos;
  var match = React.useReducer((function (state, action) {
          if (typeof action === "object") {
            return {
                    currentVideoIndex: action._0,
                    status: state.status
                  };
          }
          switch (action) {
            case "OpenModal" :
                return {
                        currentVideoIndex: state.currentVideoIndex,
                        status: "Playing"
                      };
            case "CloseModal" :
                return {
                        currentVideoIndex: state.currentVideoIndex,
                        status: "Viewing"
                      };
            case "GotoNext" :
                var match = state.status;
                if (match === "Viewing") {
                  return {
                          currentVideoIndex: state.currentVideoIndex < (videos.length - 1 | 0) ? state.currentVideoIndex + 1 | 0 : 0,
                          status: state.status
                        };
                } else {
                  return state;
                }
            
          }
        }), initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var intervalId = setInterval((function () {
                  dispatch("GotoNext");
                }), 5000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), []);
  var video = Belt_Array.get(videos, state.currentVideoIndex);
  if (video === undefined) {
    return null;
  }
  var match$1 = state.status;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(VideoThumbnailSlide.make, {
                      video: video,
                      clickHandler: (function (param) {
                          dispatch("OpenModal");
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(videos, (function (index, param) {
                              return JsxRuntime.jsx(Control.make, {
                                          inline: true,
                                          className: Cx.cx([
                                                css.dotControl,
                                                state.currentVideoIndex === index ? css.activeDot : ""
                                              ]),
                                          onClick: (function (param) {
                                              dispatch({
                                                    TAG: "GotoIndex",
                                                    _0: index
                                                  });
                                            }),
                                          children: JsxRuntime.jsx(IconDot.make, {
                                                size: "XXS",
                                                color: "White"
                                              })
                                        }, String(index));
                            })),
                      className: css.dotsContainer
                    }),
                match$1 === "Viewing" ? null : JsxRuntime.jsx(VideoPlayer.make, {
                        video: video,
                        cancelVideoPlay: (function () {
                            dispatch("CloseModal");
                          })
                      })
              ]
            });
}

var make = VideoCarousel;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
