// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactVimeo from "react-vimeo";
import * as JsxRuntime from "react/jsx-runtime";

var JsComponent = {};

function ReactVimeoVideo(props) {
  var __autoplay = props.autoplay;
  var autoplay = __autoplay !== undefined ? __autoplay : false;
  return JsxRuntime.jsx(ReactVimeo, {
              videoId: props.videoId,
              autoplay: autoplay,
              className: props.className,
              playerOptions: {
                autoplay: autoplay ? 1 : 0
              }
            });
}

var make = ReactVimeoVideo;

export {
  JsComponent ,
  make ,
}
/* react-vimeo Not a pure module */
