import * as React from 'react';

import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { toggleCart, setCartQty } from '../../../../bundles/Client/common/actions/cartActions';
import { setUserData } from '../../../../bundles/Client/common/actions/userActions';
import ProductView from './ProductView.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    toggleCart: () => dispatch(toggleCart()),
    setCartQty: qty => dispatch(setCartQty(qty)),
    setUserData: userData => dispatch(setUserData(userData)),
  },
});

const ProductViewContainer = connect(null, mapDispatchToProps)(ProductView);

export default ({ context, ...props }) => (
  <ReduxProvider {...props} {...context}>
    <ProductViewContainer props={props} context={context} />
  </ReduxProvider>
);
