// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as Globals from "Common/globals";
import * as JsxRuntime from "react/jsx-runtime";
import * as VideoCarouselScss from "./VideoCarousel.scss";

var css = VideoCarouselScss;

function VideoPlayerOverlay(props) {
  var cancelVideoPlay = props.cancelVideoPlay;
  React.useEffect((function () {
          cloudinary.Cloudinary.new({
                  cloud_name: Globals.CloudinaryCloudName,
                  secure: true
                }).videoPlayers(".cld-video-player");
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, cancelVideoPlay);
                    });
        }), []);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Control.make, {
                            className: css.closeOverlayButton,
                            onClick: (function (param) {
                                cancelVideoPlay();
                              }),
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "XL",
                                  color: "White"
                                })
                          }),
                      props.children
                    ],
                    className: css.innerOverlay
                  }),
              className: css.overlay
            });
}

var make = VideoPlayerOverlay;

export {
  css ,
  make ,
}
/* css Not a pure module */
