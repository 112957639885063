// Generated by ReScript, PLEASE EDIT WITH CARE

import ReactYoutube from "react-youtube";
import * as JsxRuntime from "react/jsx-runtime";

var JsComponent = {};

function ReactYoutubeVideo(props) {
  return JsxRuntime.jsx(ReactYoutube, {
              videoId: props.videoId,
              options: props.options,
              className: "cld-fluid"
            });
}

var make = ReactYoutubeVideo;

export {
  JsComponent ,
  make ,
}
/* react-youtube Not a pure module */
