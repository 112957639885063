// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../components/Control/Control.res.js";
import * as Picture from "../Picture/Picture.res.js";
import * as IconPlay from "../../icons/IconPlay.res.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as VideoCarouselScss from "./VideoCarousel.scss";

var css = VideoCarouselScss;

var thumbLarge = [
  420,
  315
];

var thumbMedium = [
  320,
  240
];

var thumbSmall = [
  240,
  180
];

var Const = {
  thumbLarge: thumbLarge,
  thumbMedium: thumbMedium,
  thumbSmall: thumbSmall
};

var imageNotFoundUrl = Cloudinary.imageUrl("image-not-found.png");

function VideoThumbnailSlide(props) {
  var video = props.video;
  var match = video.source;
  var tmp;
  switch (match) {
    case "Cloudinary" :
        tmp = video.url;
        break;
    case "YouTube" :
        tmp = Cloudinary.youtubeThumbUrl(video.publicId);
        break;
    case "Vimeo" :
        tmp = Cloudinary.vimeoThumbUrl(video.publicId);
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Picture.make, {
                      src: tmp,
                      large: thumbLarge,
                      medium: thumbMedium,
                      small: thumbSmall,
                      className: css.thumbnail,
                      fallbackSrc: imageNotFoundUrl
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Control.make, {
                            onClick: props.clickHandler,
                            children: JsxRuntime.jsx(IconPlay.make, {
                                  size: "XXXXL",
                                  color: "White"
                                })
                          }),
                      className: css.playControlContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: video.description,
                      className: css.videoDescription
                    })
              ],
              className: css.thumbSlide
            });
}

var make = VideoThumbnailSlide;

export {
  css ,
  Const ,
  imageNotFoundUrl ,
  make ,
}
/* css Not a pure module */
