// Generated by ReScript, PLEASE EDIT WITH CARE

import * as VideoCarousel from "../../../styleguide/forms/VideoCarousel/VideoCarousel.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DetailVideosScss from "./DetailVideos.scss";

var css = DetailVideosScss;

function DetailVideos(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(VideoCarousel.make, {
                    videos: props.videos
                  }),
              className: css.videosContainer
            });
}

var make = DetailVideos;

export {
  css ,
  make ,
}
/* css Not a pure module */
