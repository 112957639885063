// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ReactVimeoVideo from "../../../bindings/ReactVimeoVideo.res.js";
import * as ReactYoutubeVideo from "../../../bindings/ReactYoutubeVideo.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as VideoPlayerScss from "./VideoPlayer.scss";
import * as VideoPlayerOverlay from "../../forms/VideoCarousel/VideoPlayerOverlay.res.js";
import * as ReactCloudinaryVideo from "../../../bindings/ReactCloudinaryVideo.res.js";

var css = VideoPlayerScss;

function VideoPlayer(props) {
  var video = props.video;
  var match = video.source;
  var tmp;
  switch (match) {
    case "Cloudinary" :
        tmp = JsxRuntime.jsx(ReactCloudinaryVideo.make, {
              publicId: video.publicId,
              controls: true,
              autoPlay: true,
              className: "cld-video-player cld-video-player-skin-dark cld-fluid"
            });
        break;
    case "YouTube" :
        tmp = JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ReactYoutubeVideo.make, {
                    videoId: video.publicId,
                    options: {
                      playerVars: {
                        autoplay: 1
                      }
                    }
                  }),
              className: css.responsiveVideoPlayer
            });
        break;
    case "Vimeo" :
        tmp = JsxRuntime.jsx(ReactVimeoVideo.make, {
              videoId: video.publicId,
              autoplay: true,
              className: Cx.cx([
                    "cld-fluid",
                    css.vimeoPlayer
                  ])
            });
        break;
    
  }
  return JsxRuntime.jsx(VideoPlayerOverlay.make, {
              cancelVideoPlay: props.cancelVideoPlay,
              children: JsxRuntime.jsx("div", {
                    children: tmp,
                    className: css.videoPlayer
                  })
            });
}

var make = VideoPlayer;

export {
  css ,
  make ,
}
/* css Not a pure module */
